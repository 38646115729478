/**
 * @class Oskari.sample.bundle.SampleInfoBundle
 *
 * Definition for bundle. See source for details.
 */
Oskari.clazz.define("Oskari.sample.info.SampleInfoBundle",
/**
 * @method create called automatically on construction
 * @static
 */
function () {}, {
  "create": function create() {
    return Oskari.clazz.create("Oskari.sample.info.SampleInfoBundleInstance");
  },
  "update": function update(manager, bundle, bi, info) {}
}, {
  "protocol": ["Oskari.bundle.Bundle"],
  "source": {
    "scripts": [{
      "type": "text/javascript",
      "src": "../../../../bundles/sample/sample-info/instance.js"
    }],
    "locales": [{
      "lang": "fi",
      "type": "text/javascript",
      "src": "../../../../bundles/sample/sample-info/resources/locale/fi.js"
    }, {
      "lang": "sv",
      "type": "text/javascript",
      "src": "../../../../bundles/sample/sample-info/resources/locale/sv.js"
    }, {
      "lang": "en",
      "type": "text/javascript",
      "src": "../../../../bundles/sample/sample-info/resources/locale/en.js"
    }, {
      "lang": "is",
      "type": "text/javascript",
      "src": "../../../../bundles/sample/sample-info/resources/locale/is.js"
    }]
  },
  "bundle": {
    "manifest": {
      "Bundle-Identifier": "sample-info",
      "Bundle-Name": "sample-info",
      "Bundle-Author": [{
        "Name": "ev",
        "Organisation": "nls.fi",
        "Temporal": {
          "Start": "2018",
          "End": "2018"
        },
        "Copyleft": {
          "License": {
            "License-Name": "EUPL",
            "License-Online-Resource": "http://www.paikkatietoikkuna.fi/license"
          }
        }
      }],
      "Bundle-Version": "1.0.0",
      "Import-Namespace": ["Oskari"],
      "Import-Bundle": {}
    }
  }
});
Oskari.bundle_manager.installBundleClass("sample-info", "Oskari.sample.info.SampleInfoBundle");
import '../../../../bundles/sample/sample-info/instance.js'